@font-face {
  font-family: 'Quicksand';
  src: url('Quicksand-Bold.woff2') format('woff2'),
    url('Quicksand-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('Quicksand-Light.woff2') format('woff2'),
    url('Quicksand-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('Quicksand-Medium.woff2') format('woff2'),
    url('Quicksand-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('Quicksand-Regular.woff2') format('woff2'),
    url('Quicksand-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('Quicksand-SemiBold.woff2') format('woff2'),
    url('Quicksand-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
