* {
  flex-shrink: 0;
}

#root {
  transition: margin-left 0.5s ease-in-out, width 0.5s ease-in-out;
}

.__sidebar-button {
  position: fixed;
  top: 30px;
  left: -5px;
}

.__form-container {
  margin: 30px 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ui-input-container-downline {
  width: 80%;
}
.ui-select-container-downline {
  width: 80%;
}

[color='success'] {
  color: #000000 !important;
  -webkit-text-fill-color: #000000 !important;
}

[color='success'] path {
  fill: #000000 !important;
}

@media screen and (max-width: 600px) {
  .ui-modal-container {
    width: calc(100% - 30px) !important;
  }
  .ui-input-container-downline {
    width: calc(100% - 30px) !important;
  }
  .ui-select-container-downline {
    width: calc(100% - 30px) !important;
  }
  .__search-container {
    max-width: 100% !important;
  }
  .__form-container {
    margin: 10px 0;
  }
}

.__title-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 15px 0 15px;
}

.__title-header > * > input {
  text-align: center !important;
}
